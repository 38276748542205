.close-button {
    line-height: 12px;
    position:absolute;
    top: 5px;
    right: 20px;
}

.game-card {
    margin-bottom: 15px;
    border-radius: 2px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); */
    transition: 0.3s;
  }
  .game-card:hover {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }

  .game-card-body {
    padding: 1em;
  }

  .game-image {
    background-position: center;
    background-size: cover;
     height: 300px;
  }
  @media (max-width: 768px) {
    .game-image {
       height: 250px;
    }
  }
  @media (max-width: 512px) {
    .game-image {
       height: 200px;
    }
  }