#root {
    height: 100%;
}

.core {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
}

.background-image {
  min-height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #1d3557;
}

@media (max-width: 512px) {
  .background-image {
    background: none !important;
  }
}

.root-container {
  padding: 25px 0px !important;
}

.core-container {
    border-radius: 2px;
    /* background: rgba(12,12,12,0.9); */
    background: rgba(255,255,255,1);
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.6);
    /* color: white; */
    padding: 10px 30px !important;
}
  @media (max-width: 768px) {
    .core-container {
        padding: 10px 30px !important;
    }
  }
  @media (max-width: 512px) {
    .core-container {
        border-radius: 0;
        padding: 10px 20px !important;
    }
  }

  @media (max-width: 512px) {
    .root-container {
        padding: 0px 0px !important;
    }
  }

  .core-container h1 {
    font-size: 48pt;
    padding: 1rem;
    font-weight: bold;
    text-align: center;
  }

  .hr-green {
    border: 0;
    border-bottom: 2px solid #487642;
    padding-bottom: 0.25rem;
  }

  .super-title {
    display: block;
    font-weight: bold;
    font-size: 24pt;
    white-space: normal;
  }

  @media (max-width: 512px) {
    .super-title {
      display: block;
      font-weight: bold;
      font-size: 20pt;
    }
  }

  .super-icon {
    display: block;
    padding: 15px;
  }

  @media (max-width: 512px) {
    .super-icon {
      display: block;
      padding: 5px;
    }
  }

  .super-subtext {
    display: block;
    white-space: normal;
  }

  .buttoons Button {
    margin: 0px 5px;
  }

  .super-button:hover {
    opacity: 0.8;
    transition: opacity 0.3s;
  }